import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Slider from "react-slick"

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import ImageCard from '../image-card'
import Pagination from '../pagination'
import Button from '../button'
import { IconNext, IconPrevious } from '../icons'

import './index.styl'

const NextArrow = ({className, style, onClick}) => (
  <Button
    style='outline-secondary'
    onClick={onClick}
    className={classNames('hero-banners__slick-btn', 'hero-banners__slick-btn--next')}
  >
    <IconNext color='white' />
  </Button>
)

const PrevArrow = ({className, style, onClick}) => (
  <Button
    style='outline-secondary'
    onClick={onClick}
    className={classNames('hero-banners__slick-btn', 'hero-banners__slick-btn--prev')}
  >
    <IconPrevious color='white' />
  </Button>
)

const HeroBanners = ({ className, banners = [] }) => {
  const [pageProps, setPageProps] = useState({ current: 1 })

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const hanglePageClick = page => {
    setPageProps({
      ...pageProps,
      current: page,
    })
  }

  useEffect(() => {
    setPageProps({
      ...pageProps,
      total: banners.length,
    })
  }, [banners])

  return (
    <div className="hero-banners">
      <div
        className="hero-banners__wrap"
      >
        <Slider {...settings}>
        {banners.map(banner => (
          <div>
            <div key={banner['_id']} className="hero-banners__item">
              <ImageCard
                className="hero-banners__item-image"
                mode="background"
                imgUrl={`https://www.duro.com.tw:8088/api/v1.0/image/${banner.image}`}
              />
              <div className="hero-banners__item-content">
                <h2 className="hero-banners__item-title">{banner.title}</h2>
                <p className="hero-banners__item-description">
                  {banner.description}
                </p>
              </div>
              {banner.link && (
                <Button
                  style="outline-secondary"
                  className="hero-banners__item-nav-link"
                  href={banner.link}
                >
                  learn more
                </Button>
              )}
            </div>
          </div>
        ))}
        </Slider>
      </div>
    </div>
  )
}

HeroBanners.propTypes = {}

export default HeroBanners
