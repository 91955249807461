import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useParams, useLocation, navigate } from '@reach/router'
import { URLSearchParams } from 'universal-url'

import union from 'lodash-es/union'

import { IconLoader } from '../icons'
import FilterBar from '../filter-bar'
import Pagination from '../pagination'
import ProductCard from '../product-card'

import sleep from '../../utils/sleep'
import { getFilterOptions } from '../../utils/filters'
import { generateResult } from '../../utils/results'

import './index.styl'

const FILTERS_MAP_DEFAULT = {
  carType: {
    name: 'carType',
    label: 'carType',
    disabled: false,
    items: [],
  },
  brand: {
    name: 'brand',
    label: 'brand',
    disabled: false,
    items: [],
  },
  model: {
    name: 'model',
    label: 'model',
    disabled: false,
    items: [],
  },
  category: {
    name: 'category',
    label: 'category',
    disabled: false,
    items: [],
  },
  size: {
    name: 'size',
    label: 'size',
    disabled: false,
    items: [],
  },
  productId: {
    name: 'productId',
    label: 'productId',
    disabled: false,
    items: [],
  }
}

const PAGE_DEFAULT = {
  perPage: 10,
  total: 100,
  current: 1,
}

const PRODUCT_SEARCH_ALIGN = {
  left: 'product-search--align-left',
}

const ProductSearch = ({
  site,
  showTitle = true,
  showFilter = true,
  perPage = 10,
  perRow = 5,
  align = 'center',
  types = [],
  sitePrefix,
  screen,
  style,
  allProducts,
  selectedType,
}) => {
  const filters = site === 'en' ? ['carType', 'category', 'size', 'productId'] :  ['carType', 'category', 'size', 'brand', 'model', 'productId']
  const params = useParams()
  const location = useLocation()

  const [activeType, setActiveType] = useState(null)

  const [filterStatus, setFilterStatus] = useState({})
  const [filtersMap, setFiltersMap] = useState(FILTERS_MAP_DEFAULT)
  const [pageProps, setPageProps] = useState({ current: 1, perPage, total: 0 })
  const [isSearching, setIsSearching] = useState(false)
  const [result, setResult] = useState([])
  const [singleCardWidth, setSingleCardWidth] = useState([210])

  const handleFilterChange = ({ name, selected }) => {
    let newStatus = { ...filterStatus, [name]: selected }
    if (newStatus['carType'].value === null ){
      newStatus = {
        carType: {
          label: filtersMap['carType'].label,
          value: null
        },
        category: {
          label: filtersMap['category'].label,
          value: null
        },
        size: {
          label: filtersMap['size'].label,
          value: null
        },
        productId: {
          label: filtersMap['productId'].label,
          value: null
        },
        brand: {
          label: filtersMap['brand'].label,
          value: null          
        },
        model: {
          label: filtersMap['model'].label,
          value: null          
        },
      }

      setFiltersMap({
        ...filtersMap,
        category: {
          ...filtersMap['category'],
          items: []
        },
        size: {
          ...filtersMap['size'],
          items: []
        },
        productId: {
          ...filtersMap['productId'],
          items: []
        },
        brand: {
          ...filtersMap['brand'],
          items: []
        },
        model: {
          ...filtersMap['model'],
          items: []
        },
      })

      setFilterStatus(newStatus)
      return
    }

    switch (name) {
      case 'carType':
        newStatus = {
          ...newStatus,
          category: {
            label: filtersMap['category'].label,
            value: null
          },
          size: {
            label: filtersMap['size'].label,
            value: null
          },
          brand: {
            label: filtersMap['brand'].label,
            value: null
          },
          model: {
            label: filtersMap['model'].label,
            value: null
          },
        }
        break;
      case 'category':
        newStatus = {
          ...newStatus,
          size: {
            label: filtersMap['size'].label,
            value: null
          },
          brand: {
            label: filtersMap['brand'].label,
            value: null
          },
          model: {
            label: filtersMap['model'].label,
            value: null
          },
        }
        break
      case 'size':
        newStatus = {
          ...newStatus,
          brand: {
            label: filtersMap['brand'].label,
            value: null
          },
          model: {
            label: filtersMap['model'].label,
            value: null
          },
        }
        break
      case 'brand':
        newStatus['model'] = {
          label: filtersMap['model'].label,
          value: null
        }
        break
    }

    if (name !== 'productId') {
      newStatus['productId'] = {
        label: filtersMap['productId'].label,
        value: null
      }
    }

    const selectedType = types.filter(
      t => t.id === newStatus['carType'].value
    )[0]

    const options = getFilterOptions(name, newStatus, types)

    setFiltersMap({
      ...filtersMap,
      ...( options.category.length > 0  ? {
        category: {
          ...filtersMap['category'],
          items: options.category
        }        
      } : {}),
      ...( options.size.length > 0  ? {
        size: {
          ...filtersMap['size'],
          items: options.size,
        }
      } : {}),
      ...( options.productId.length > 0  ? {
        productId: {
          ...filtersMap['productId'],
          items: options.productId,
        }
      } : {}),
      ...( (options.brand.length > 0 || (name !== 'brand' && name !== 'model')) ? {
        brand: {
          ...filtersMap['brand'],
          items: options.brand,
        }
      } : {}),
      ...( (options.model.length > 0 || (name !== 'brand' && name !== 'model')) ? {
        model: {
          ...filtersMap['model'],
          items: options.model,
        }
      } : {}),
    })

    setFilterStatus(newStatus)
  }

  const porductFilter = async filterStatus => {
    const result = allProducts.filter(p => {
      const brands = []
      const models = []

      if(p.brand) {
        p.brand.forEach( ({ name, model }) => {
          if(brands.indexOf(name) === -1) brands.push(name)
          if(models.indexOf(model) === -1) models.push(model)
        })
      }

      return (
        (!filterStatus['carType'] || !filterStatus['carType'].value ||
          p.type.value === filterStatus['carType'].value) &&
        (!filterStatus['category'] || !filterStatus['category'].value ||
          p.cat.value === filterStatus['category'].value) &&
        (!filterStatus['size'] || !filterStatus['size'].value ||
          p.sizes.filter(s => s['Tire_Size'] === filterStatus['size'].value)
            .length > 0) &&
        (!filterStatus['productId'] || !filterStatus['productId'].value ||
          p.productID === filterStatus['productId'].value)
        ) && (!filterStatus['brand'] || !filterStatus['brand'].value ||
          brands.indexOf(filterStatus['brand'].value) > -1
        ) && (!filterStatus['model'] || !filterStatus['model'].value ||
          models.indexOf(filterStatus['model'].value) > -1
        ) 
    })

    await sleep(500)
    return result
  }

  const handleSearch = async data => {
    const urlParams = new URLSearchParams(location.search)

    setIsSearching(true)
    const searchResult = await porductFilter(filterStatus)
    setPageProps({
      ...pageProps,
      current: parseInt(urlParams.get('p')) || 1,
      total: searchResult.length,
    })

    setResult(searchResult)
    setIsSearching(false)
  }

  const hanglePageClick = page => {
    if (!showFilter) {
      const urlParams = new URLSearchParams(location.search)
      urlParams.set('p', page)
      navigate(`${location.pathname}?${urlParams.toString()}`)
    }

    setPageProps({
      ...pageProps,
      current: page,
    })
  }

  useEffect(() => {
    if (!showFilter && filterStatus) {
      handleSearch()
    }
  }, [filterStatus])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const cat = urlParams.get('cat')
    if (cat) {
      setFilterStatus({
        category: { value: cat },
      })
    }
  }, [location])

  useEffect(() => {
    setPageProps({
      ...pageProps,
      perPage,
    })
  }, [perPage])

  useEffect(() => {
    if (!showFilter) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }, [pageProps])

  useEffect(() => {
    if (selectedType) {
      const urlParams = new URLSearchParams(location.search)
      setFilterStatus({
        carType: { value: selectedType.id },
        ...(urlParams.get('cat')
          ? { category: { value: urlParams.get('cat') } }
          : {}),
      })
    }

    if (showFilter) {
      const typeItems = types.map(d => ({ label: d.title, value: d.id }))
      setFiltersMap({
        ...filtersMap,
        carType: {
          ...filtersMap['carType'],
          items: typeItems,
        },
      })
      handleSearch()
    }
  }, [])

  useEffect(() => {
    setSingleCardWidth(screen === 'sm' || screen === 'xs' ? 170 : 210)
  }, [screen])

  return (
    <div
      className={classNames(
        'product-search', 
        PRODUCT_SEARCH_ALIGN[align],
        {
          'product-search--with-title': showTitle,
        }        
      )}
      style={style}
    >
      {showTitle && <h2 className="product-search__title">Tire Finder</h2>}
      <div
        className="product-search__content"
        style={{ width: `${perRow * singleCardWidth - 10}px` }}
      >
        {showFilter && (
          <FilterBar
            className="product-search__filter-bar"
            filters={filters.map(filter => filtersMap[filter])}
            onChange={handleFilterChange}
            onSearch={handleSearch}
            disabled={isSearching}
            filterStatus={filterStatus}
            wrap={screen === 'md' || screen === 'sm'}
            mode={ site === 'en' ? 'normal' : 'row' }
          />
        )}
        <div
          className={classNames('product-search__list', {
            'product-search__list--fetching': isSearching,
          })}
        >
          {result
            .slice(
              (pageProps.current - 1) * pageProps.perPage,
              pageProps.current * pageProps.perPage
            )
            .map((d, i) => (
              <ProductCard sitePrefix={sitePrefix} key={`${d.id}-${i}`} {...d} />
            ))}
        </div>
        {isSearching && <IconLoader />}
        <Pagination
          {...pageProps}
          pageClick={hanglePageClick}
          disabled={isSearching}
          className="product-search__pagination"
        />
      </div>
    </div>
  )
}

ProductSearch.propTypes = {}

export default ProductSearch
