import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Filter from './filter'
import Button from '../button'

import { IconSearch } from '../icons'

import './index.styl'

const FilterBar = ({
  filters = [],
  className,
  onChange,
  onSearch,
  disabled,
  wrap = false,
  mode = 'normal',
  filterStatus,
}) => {

  const [condition, setCondition] = useState({})
  const [cols, setCols] = useState([0, 1, 2])
  const [rows, setRows] = useState([0])

  const handleOnChange = name => selected => {
    setCondition({ ...condition, [name]: selected })
    if (typeof onChange === 'function') onChange({ name, selected })
  }

  const handleSearchClick = () => {
    const conditionValues = Object.keys(condition).filter(key => condition[key]).map(key => ({
      [key]: condition[key].value,
    }))
    if (typeof onSearch === 'function') onSearch(conditionValues)
  }

  useEffect(() => {
    const rowCount = Math.ceil(filters.length / 3)
    const rows = []

    for(let i = 0; i < rowCount; i += 1) {
      rows.push(i)
    }

    setRows(rows)

  }, [filters])

  return (
    <div
      className={classNames(
        'filter-bar',
        { 'filter-bar--wrap': wrap },
        className
      )}
    >
      {
        mode === 'normal' ? filters.map(filter => (
          <Filter
            {...filter}
            key={filter.name}
            disabled={disabled || filter.disabled}
            selected={ (filterStatus[filter.name] && filterStatus[filter.name].value) ? filterStatus[filter.name] : null}
            onChange={handleOnChange(filter.name)}
            useSelect={true}
          />
        )) : rows.map((r, i) => (
        <div
          className={classNames(
            'filter-bar__row',
            { 'filter-bar__row--last': i === rows.length - 1 },
          )}            
        >
          {
            cols.map((c, j) => {
              const index = i*3 + j
              const filter = filters[index]
              return filter ? (
                  <Filter
                    {...filter}
                    key={filter.name}
                    disabled={disabled || filter.disabled}
                    selected={ (filterStatus[filter.name] && filterStatus[filter.name].value) ? filterStatus[filter.name] : null}
                    onChange={handleOnChange(filter.name)}
                    useSelect={true}
                  />
                ) : null
            })
          }
          {
            i === rows.length - 1 && (
              <Button style="primary" disabled={disabled} onClick={handleSearchClick}>
                <IconSearch />
              </Button>
            )
          }
        </div>
        ))
      }
      {
        mode === 'normal' && (
          <Button style="primary" disabled={disabled} onClick={handleSearchClick}>
            <IconSearch />
          </Button>
        )
      }
    </div>
  )
}

FilterBar.propTypes = {}

export default FilterBar
