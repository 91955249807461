import union from 'lodash-es/union'

let brandsData = {}

export const getFilterOptions = (filterName, status, types) => {

  const selectedType = types.filter(t => t.id === status['carType'].value)[0]
  const categories = selectedType.categories

  let sizes = []
  let productIDs = []
  let brands = []
  let models = []

  let brandsData = {}

  if (filterName !== 'brand' && filterName !== 'model' && filterName !== 'productId') {
    // brandsData = {}
  }

  const selectedCats = status['category'].value
    ? selectedType.categories.filter(c => c.key === status['category'].value)
    : categories

  selectedCats.forEach(cat => {
    const sizesData = cat.sizesData
    const keys = Object.keys(sizesData)
    sizes = union(sizes, keys)

    const selectedSizes = status['size'].value ? [status['size'].value] : keys

    selectedSizes.forEach( size => {
      const sizeData = sizesData[size]
      if (sizeData) {
        productIDs = union(productIDs, sizeData.productIDs)
        const brandKeys = Object.keys(sizeData.brandsData)
        brandKeys.forEach(brand => {
          if (!brandsData[brand]) {
            brandsData[brand] = sizeData.brandsData[brand]
          } else {
            const modelKeys = Object.keys(sizeData.brandsData[brand])
            modelKeys.forEach(model => {
              if(!brandsData[brand][model]) brandsData[brand][model] = []
              brandsData[brand][model] = union(brandsData[brand][model], sizeData.brandsData[brand][model])
            })
          }
        })        
      }
    })
  })

  brands = Object.keys(brandsData).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))

  const selectedBrands = status['brand'] && status['brand'].value 
    ? brands.filter( b => b === status['brand'].value ) : brands

  if ((status['model'] && status['model'].value) || (status['brand'] && status['brand'].value)) {
    productIDs = [] 
  }

  selectedBrands.forEach( brand => {
    const modelKeys = Object.keys(brandsData[brand])
    models = union(models, modelKeys)

    if (status['model'] && status['model'].value) {
      productIDs = union(productIDs, brandsData[brand][status['model'].value]) 
    } else if (status['brand'] && status['brand'].value) {
      for(let i = 0; i < modelKeys.length; i += 1) {
        const key = modelKeys[i]
        productIDs = union(productIDs, brandsData[brand][key])        
      }
    }

  })

  return ({
    category: categories.map(c => ({ label: c.label, value: c.key })),
    size: sizes.map(s => ({ label: s, value: s })),
    productId: productIDs.map(id => ({ label: id, value: id })),
    brand: brands.map(brand => ({ label: brand, value: brand })),
    model: models.map(model => ({ label: model, value: model }))
  })

}
