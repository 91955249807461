import React, { useState, useEffect } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroBanners from '../components/hero-banners'
import ProductTypes from '../components/product-types'
import ProductSearch from '../components/product-search'
import DealerSearch from '../components/dealer-search'

import useScreen from '../hooks/use-screen'

const SEARCH_RWD_PROPS = {
  xl: {
    perPage: 10,
    perRow: 5,
  },
  lg: {
    perPage: 8,
    perRow: 4,
  },
  md: {
    perPage: 6,
    perRow: 3,
  },
  sm: {
    perPage: 6,
    perRow: 3,
  },
  xs: {
    perPage: 4,
    perRow: 2,
  },
}

const LandingPage = ({ pageContext }) => {
  const {
    banners,
    types,
    lang,
    allProducts,
    sitePrefix,
    siteTitle = 'DURO TIRE',
    dealers,
  } = pageContext

  const screen = useScreen()
  const [searchPros, setSearchProps] = useState(
    SEARCH_RWD_PROPS[screen] || SEARCH_RWD_PROPS['xl']
  )

  useEffect(() => {
    setSearchProps(SEARCH_RWD_PROPS[screen] || SEARCH_RWD_PROPS['xl'])
  }, [screen])

  useEffect(() => {
    /*
    ;(async () => {
      const res = await fetch(
        'http://localhost:8888/api/fetchDuroDB/site',
        //'http://61.221.85.77:8888/api/fetchDuroDB/site',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ site: 'zh' })      
        }
      ).then(rsp => rsp.json())
      console.log(res)
    })()
    */
  }, [])

  return (
    <Layout { ...pageContext }>
      <SEO title={siteTitle} lang={lang} />
      <HeroBanners banners={banners} />
      <ProductTypes screen={screen} types={types} sitePrefix={sitePrefix}/>
      <ProductSearch
        site={lang}
        sitePrefix={sitePrefix}
        screen={screen}
        allProducts={allProducts}
        types={types}
        {...searchPros}
      />
      { dealers.length > 0 && <DealerSearch dealers={dealers}/> }
    </Layout>
  )
}

export default LandingPage
