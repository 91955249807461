import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../../button'

import './index.styl'

const Filter = ({
  label = 'Filter Label',
  disabled = false,
  items = [],
  onChange,
  selected,
  useSelect = false,
}) => {
  const { t } =  useTranslation()

  const selectEl = useRef(null)
  const [selectedLabel, setSelectedLabel] = useState(null)

  const [hovered, setHovered] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)

  const closeMenu = e => {
    if (!hovered) setMenuOpened(false)
  }

  const handleMenuToggle = () => {
    setMenuOpened(!menuOpened)
  }

  const handleMenuItemClick = item => () => {
    if (typeof onChange === 'function') onChange(item)
    setMenuOpened(false)
  }

  const handleSelectChange = (e) => {
    const selectedItem = items.filter(
      d => d.value === e.target.value
    )[0] || {label, value: null}

    if (typeof onChange === 'function') onChange(selectedItem)
  }

  useEffect(() => {
    if (items.length > 0) {
      document.addEventListener('click', closeMenu)
      return () => {
        document.removeEventListener('click', closeMenu)
      }
    }
  })

  useEffect(() => {
    if (selected) setSelectedLabel(selected.label)
  }, [selected])

  return items.length >= 0 ? (
    <div
      className="filter"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      {useSelect ? (
        <select
          className="filter__select"
          disabled={disabled || items.length === 0}
          //ref={selectEl}
          value={selected ? selected.value : null}
          onChange={handleSelectChange}
          defaultValue={selected && selected.value}
        >
          <option key={label} value={null} selected={selected ? selected.value === null : false}>
            {t(`filters.${label}`)}
          </option>
          {items.map(item => (
            <option key={item.value} value={item.value} selected={selected ? selected.value === item.value : false}>
              {item.label}
            </option>
          ))}
        </select>
      ) : (
        <>
          <Button
            className="filter__toggle-btn"
            onClick={handleMenuToggle}
            disabled={disabled}
          >
            {selectedLabel || label}
          </Button>
          {menuOpened && (
            <div className="filter__menu">
              {items.map(item => (
                <Button
                  key={item.value}
                  onClick={handleMenuItemClick(item)}
                  className="filter__menu-item"
                  className={classNames('filter__menu-item', {
                    'filter__menu-item--active':
                      selected && selected.value === item.value,
                  })}
                >
                  {item.label}
                </Button>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  ) : null
}

Filter.propTypes = {}

export default Filter
