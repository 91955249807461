import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { IconLoader, IconGoto } from '../icons'
import Button from '../button'
import ImageCard from '../image-card'
import Pagination from '../pagination'

import './index.styl'

const ProductTypes = ({
  sitePrefix,
  showTitle = true,
  gutter = false,
  types,
  screen,
}) => {
  const [activeType, setActiveType] = useState(types[0])
  const [pageProps, setPageProps] = useState({ current: 1 })

  const { t } =  useTranslation()

  const handleTypeClick = type => () => {
    setActiveType(type)
  }

  const hanglePageClick = page => {
    setPageProps({
      ...pageProps,
      current: page,
    })
  }

  const getShowCategories = () => {
    return screen !== 'xs' 
      ? activeType.categories.slice(
        (pageProps.current - 1) * 4,
        pageProps.current * 4
      )
      : activeType.categories
  }

  useEffect(() => {
    if (activeType) {
      setPageProps({
        ...pageProps,
        total: Math.ceil(activeType.categories.length/4),
        current: 1,
      })
    }
  }, [activeType])

  return types.length > 0 ? (
    <div
      className={classNames('product-type', { 'product-type--gutter': gutter })}
    >
      {showTitle && <h2 className="product-type__title">product</h2>}
      <div className="product-type__content">
        <aside className="product-type__aside">
          <ul className="product-type__menu">
            {types.map(type => (
              <li
                key={type.slug}
                onClick={handleTypeClick(type)}
                className={classNames('product-type__menu-item', {
                  'product-type__menu-item--active':
                    type.slug === activeType.slug,
                })}
              >
                {type.title}
              </li>
            ))}
          </ul>
        </aside>
        <div className="product-type__main">
          <div className="product-type__cover-types">
            <div className="product-type__cover">
              <ImageCard
                className="product-type__cover-img"
                imgUrl={activeType.cover}
                mode={
                  screen === 'md' || screen === 'sm' ? 'background' : 'image'
                }
              />
            </div>
            <div className="product-type__categories">
              {getShowCategories().map(cat => (
                <a
                  className="product-type__category-link"
                  key={cat.key}
                  href={`${sitePrefix}/product/${activeType.slug}/?cat=${cat.key}`}
                >
                  {cat.label}
                </a>
              ))}
              {
                screen !== 'xs' && pageProps.total > 1 && (
                  <Pagination
                    {...pageProps}
                    pageClick={hanglePageClick}
                    style="light"
                    className="product-type__pagination"
                    showPageBtn={false}
                    size="small"
                  />
                )
              }
            </div>
          </div>
          <div className="product-type__introduction-links">
            <div className="product-type__introduction">
              <h3 className="product-type__sub-title">{activeType.label}</h3>
              <p className="product-type__description">
                {activeType.description}
              </p>
            </div>
            <div className="product-type__links">
              <Button
                style="primary"
                className="product-type__cat-link"
                link={`${sitePrefix}/product/${activeType.slug}`}
              >
                <IconGoto />
              </Button>
              <Button
                style="secondary"
                className="product-type__pdf-link"
                target="_blank"
                href={activeType.pdfLink}
              >
                { t('types.catalogue') }
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

ProductTypes.propTypes = {}

export default ProductTypes
