import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import union from 'lodash/union'

import { IconLoader } from '../icons'
import FilterBar from '../filter-bar'

import sleep from '../../utils/sleep'
import { generateResult } from '../../utils/results'

import './index.styl'

const FILTERS_MAP_DEFAULT = {
  description: {
    name: 'description',
    label: 'tireType',
    disabled: false,
    items: [],
  },
  state: {
    name: 'state',
    label: 'state',
    disabled: false,
    items: [],
  },
  city: {
    name: 'city',
    label: 'city',
    disabled: false,
    items: [],
  },
}

const CITY_MAP = {
  '基隆': 1,
  '台北': 2,
  '新北': 3,
  '桃園': 4,
  '新竹': 5,
  '苗栗': 6,
  '台中': 7,
  '彰化': 8,
  '南投': 9,
  '雲林': 10,
  '嘉義': 11,
  '台南': 12,
  '高雄': 13,
  '屏東': 14,
  '台東': 15,
  '花蓮': 16,
  '宜蘭': 17,
  '澎湖': 18,
  '金門': 19,
}

const DealerSearch = ({dealers}) => {
  const { t } =  useTranslation()
  const filters = ['description', 'city']

  const [isSearching, setIsSearching] = useState(false)

  const [results, setResults] = useState([])
  const [filterStatus, setFilterStatus] = useState({})
  const [filtersMap, setFiltersMap] = useState(FILTERS_MAP_DEFAULT)

  const dealerFilter = async filterStatus => {
    const result = dealers.filter(d => {
      return (
        (!filterStatus['description'] || !filterStatus['description'].value ||
          d.description === filterStatus['description'].value) &&
        (!filterStatus['state'] || !filterStatus['state'].value ||
          d.state === filterStatus['state'].value) &&
        (!filterStatus['city'] || !filterStatus['city'].value ||
          d.city === filterStatus['city'].value)
      )})

    await sleep(500)
    return result
  }


  const handleSearch = async data => {
    setIsSearching(true)
    const searchResult = await dealerFilter(filterStatus)
    setResults(searchResult)
    setIsSearching(false)
  }

  const handleFilterChange = ({ name, selected }) => {
    setFilterStatus({
      ...filterStatus,
      [name]: selected,
    })
  }

  useEffect(() => {
    let descriptionItems = []
    let stateItems = []
    let cityItems = []
    dealers.forEach( d => {
      const { description, state, city } = d
      descriptionItems = union(descriptionItems, [description])
      stateItems = union(stateItems, [state])
      cityItems = union(cityItems, [city])
    })

    cityItems.sort((a,b) => CITY_MAP[a] - CITY_MAP[b])

    setFiltersMap({
      description: {
        ...filtersMap.description,
        items: descriptionItems.map(item => ({ label: item, value: item })),
      },
      state: {
        ...filtersMap.state,
        items: stateItems.map(item => ({ label: item, value: item })),
      },
      city: {
        ...filtersMap.city,
        items: cityItems.map(item => ({ label: item, value: item })),
      },
    })
    // handleSearch()
  }, [dealers])

  return (
    <div className="dealer-search">
      <div className="dealer-search__main">
        <h3 className="dealer-search__title">{t('dealers.title')}</h3>
        <FilterBar
          className="dealer-search__filter-bar"
          filters={filters.map(key => filtersMap[key])}
          onSearch={handleSearch}
          disabled={isSearching}
          filterStatus={filterStatus}
          onChange={handleFilterChange}
        />
        <div
          className={classNames('dealer-search__content', {
            'dealer-search__content--fetching': isSearching,
          })}
        >
          <ul>
            {results.map((d, i) => (
              <li className="dealer-search__result-item" key={i}>
                <div className="dealer-search__result-item-title">{`${d.title}${
                  d.link ? `|${d.link}` : ''
                }`}</div>
                <div className="dealer-search__result-item-address">
                  {d.address}
                </div>
              </li>
            ))}
          </ul>
          {isSearching && <IconLoader />}
        </div>
      </div>
    </div>
  )
}

DealerSearch.propTypes = {}

export default DealerSearch
